import React from 'react';
import { Container, Card } from 'react-bootstrap';
import perksData from '../json/perks-card.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Perks() {
  return (
    <div>
      <div className='common-background'>
        <Container className='pt-lg-5 pb-2 pb-lg-4'>
          <div className='fnt-20 fnt-lg-32 fw-700 text-white d-flex justify-content-center mb-4 mb-lg-5'>
            Perks of working with us
          </div>
          <div className='d-flex justify-content-center'>
            <div className='row tab-section mx-0 w-100'>
              {perksData.map((perk: any, index: any) => (
                <div
                  key={perk.id}
                  className={`col-md-12 col-xl-6 col-xxl-6 mb-3 ps-xl-0 ps-xxl-0 each-column-of-row ${(index = index % 2 === 1 ? 'last-column-of-row' : 'each-card-perks-padding')}`}
                >
                  <Card className='h-100 common-card-effect'>
                    <Card.Body>
                      <Card.Title className='d-flex'>
                        <LazyLoadImage effect='blur' src={perk.img} alt='Perks Icon' />
                        <div className='d-flex align-items-center ms-3'>
                          <div className='fw-700 fnt-18 fnt-lg-24 fw-700'>{perk.title}</div>
                        </div>
                      </Card.Title>
                      <Card.Text className='fnt-14 fnt-lg-16'>{perk.details}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Perks;
