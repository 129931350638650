import { Link } from 'gatsby';
import React from 'react';
import { Button, Container } from 'react-bootstrap';

function Contact() {
  return (
    <div className=''>
      <Container>
        <div className='d-flex justify-content-between align-items-center  py-4 py-lg-5  flex-column flex-md-row'>
          <div className='w-sm-100 w-85'>
            <div className='fw-700 textm-md-center fnt-20  fnt-lg-32  lh-36 lh-sm-24 color-black pb-1 w-lg-75 mb-2'>
              Empower your business with our cutting-edge solutions!
            </div>
            <div className='fnt-14 textm-md-center   fnt-lg-16 lh-22 color-grey mb-3 mb-md-0'>
              Open doors to new opportunities. Share your details to access exclusive benefits and
              take your business to the next level.
            </div>
          </div>
          <div className='d-flex align-items-md-center'>
            <Link to='/contact-us'>
              <Button className='btn-contact-us border-0 my-auto fnt-14 fnt-lg-16'>
                Connect Now
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Contact;
