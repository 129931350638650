import React from 'react';
import Acnavbar from '../components/Acnavbar';
import Contact from '../components/Contact';
import Footer from '../components/footer';
import ProductDescription from '../components/ProductDescription';
import MeetOurTeam from '../components/MeetOurTeam';
import JoinOurTeam from '../components/JoinOurTeam';
import Perks from '../components/Perks';
import Seo from '../shared/seo';
import Wrapper from '../components/Wrapper';

function career() {
  return (
    <Wrapper>
      <Seo
        title='Join Softobotics: Exciting Careers in IT Solutions'
        description='Explore rewarding career opportunities at Softobotics. Join our team of talented professionals in shaping the future of IT solutions and digital transformation.'
      />
      <div>
        <Acnavbar home={false} pathname={'careers'} logo={''} logoLogic={false} />
        <ProductDescription
          topheader='CAREERS'
          header='Join Softobotics: Exciting Careers in IT Solutions'
          headerText='Explore rewarding career opportunities at Softobotics. Join our team of talented professionals in shaping the future of IT solutions and digital transformation.'
          showButton={false}
        />
        <JoinOurTeam />
        <Perks />
        <MeetOurTeam home={false} />
        <div className='bg-white'>
          <Contact />
        </div>

        <Footer />
      </div>
    </Wrapper>
  );
}

export default career;
