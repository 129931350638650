import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import teampicdesktop1 from '../assets/images/team-desk.svg';
import teampicdesktop2 from '../assets/images/team-desk1.svg';
import teampicdesktop3 from '../assets/images/team-desk2.svg';
import teampicdesktop4 from '../assets/images/team-desk3.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function MeetOurTeam({
  background,
  textColor,
  home,
}: {
  background?: string;
  textColor?: string;
  home: boolean;
}) {
  return (
    <div className='meet-team'>
      <div className={`${background || 'common-background'} bg-cover py-0 py-lg-4`}>
        <Container className={`custom-carousel ${!home ? 'py-3 py-lg-5' : ''}`}>
          <div
            className={`${textColor || 'text-white'} fnt-20 fnt-lg-32 fw-700 d-flex justify-content-center mb-4 mb-md-4  lh-sm-14`}
          >
            Meet Our Team
          </div>
          <Carousel>
            <Carousel.Item>
              <LazyLoadImage
                effect='blur'
                className='d-block w-100 px-2'
                src={teampicdesktop1}
                alt='First slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <LazyLoadImage
                effect='blur'
                className='d-block w-100 px-2'
                src={teampicdesktop2}
                alt='Second slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <LazyLoadImage
                effect='blur'
                className='d-block w-100 px-2'
                src={teampicdesktop3}
                alt='Third slide'
              />
            </Carousel.Item>
            <Carousel.Item>
              <LazyLoadImage
                effect='blur'
                className='d-block w-100 px-2'
                src={teampicdesktop4}
                alt='Fourth slide'
              />
            </Carousel.Item>
          </Carousel>
        </Container>
      </div>
    </div>
  );
}

export default MeetOurTeam;
