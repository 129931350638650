import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import jointeamsdata from '../json/join-our-team.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../assets/scss/index.scss';

function JoinOurTeam() {
  return (
    <div>
      <div className='common-background bg-cover'>
        <Container className='pt-4 py-lg-5'>
          <div className='fnt-lg-32 fnt-20 fw-700 text-white d-flex justify-content-center mb-4 mb-lg-5'>
            Join Our Team
          </div>
          <div className='row tab-section ms-0 mx-0'>
            {jointeamsdata.map((joinTeam: any, index: any) => (
              <div
                key={joinTeam.name}
                className={`col-md-12 col-lg-6 col-xl-4 mb-3 ps-xl-0 ps-xxl-0 each-column-of-row ${(index = index % 3 === 2 ? 'last-column-of-row' : 'each-card-padding')}`}
              >
                <Card className='h-100 common-card-effect'>
                  <Card.Body className='my-lg-4'>
                    <Card.Title className='d-flex align-items-center flex-column'>
                      <LazyLoadImage
                        effect='blur'
                        src={joinTeam.img}
                        alt='JoinTeam Icon'
                        className='careerIcon'
                      />
                      <div className='text-center mt-3'>
                        <div className='fw-700 fnt-18 fnt-lg-24'>{joinTeam.role}</div>
                      </div>
                    </Card.Title>
                    <Card.Text className='px-4 fnt-14 fnt-lg-16'>{joinTeam.short_desc}</Card.Text>
                    <div className='d-flex justify-content-center'>
                      <Link to={`/careers${joinTeam.slug}`}>
                        <Button className='border-0 join-btn-contact-us fnt-14 fnt-lg-16'>
                          View
                        </Button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default JoinOurTeam;
